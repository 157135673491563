export default {
  "footer_company":"Came",
  "title":"Came Connect IoT",
  "header_logo":"came_logo_header.png",
  "sidenav_logo":"came_logo_sidenav.png",
  "sidenav_text":"<h1> <span>Connect.</span> <strong>Anything.</strong> <span>Anywhere.</span> </h1>",
  "company":"Came",
  "use_proxy":true,
  "show_footer_logo":true
}

